<template>
	<div class="partial partial--videos videos">
		<div class="videos-nav">
			<div class="videos-nav__content">
				<div class="videos-nav__logo">
					<router-link :to="{name: 'dashboard' }">
						<img src="~@/assets/logo_dtcc-white.png" alt="DTCC" width="98" />
					</router-link>
				</div>
				<div class="videos-nav__actions">
					<router-link :to="{name: 'dashboard' }">
						<close-icon :color="`#ffffff`" />
					</router-link>
				</div>
			</div>
		</div>
		<div class="videos-body">
			<video-embed :videoId="videoId" class="videos-embed" />
		</div>
	</div>
</template>

<script>
import CloseIcon from '@/components/CloseIcon'
import VideoEmbed from '@/components/VideoEmbed'

export default {
	name: 'PartialVideos',
	components: {
		CloseIcon,
		VideoEmbed,
	},
	computed: {
		videoId() {
			const { videoId } = this.$route.params

			return videoId
		},
	},
}
</script>

<style scoped lang="scss">
.videos {
	background-color: color_('primary', 'dark');
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	&-nav {
		display: flex;
		justify-content: center;
		//background: linear-gradient(0deg, rgba(0,0,0,0) 25%, rgba(0,0,0,1) 100%);
		// position: absolute;
		width: 100%;
		// z-index: 1;

		&__content {
			@include gutter('padding-top', 1.25);
			@include gutter('padding-bottom', 1.25);
			@include layout();
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: $container-max-width;
			//margin: 0 auto;
			width: 100%;
			font-family: $font-family-secondary;

			@include media-query-min-width('desktop') {
				@include gutter('padding-top', 2.5);
				@include gutter('padding-bottom', 2.5);
			}
		}

		&__logo img {
			animation: fadeIn 2000ms $ease-out 500ms 1 forwards;
			opacity: 0;
			max-width: 50px;

			@include media-query-min-width('tablet') {
				max-width: initial;
			}
		}

		&__actions {
			transition: opacity $transition-1;
			opacity: 1;

			svg {
				animation: fadeIn 2000ms $ease-out 500ms 1 forwards;
				opacity: 0;
				max-width: 20px;

				@include media-query-min-width('tablet') {
					max-width: initial;
				}
			}

			&:hover {
				opacity: .5;
			}
		}
	}

	&-body {
		flex: 1 0 auto;
		padding: 0 10% 2rem 10%;
		width: 100%;
	}

	&-embed {
		height: 100%;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
</style>
