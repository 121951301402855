<template>
	<div class="video-embed">
		<iframe :src="videoSrc" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
	</div>
</template>

<script>
export default {
	name: 'VideoEmbed',
	data: () => ({
	}),
	props: {
		videoId: {
			type: String,
			default: '388588872',
		},
	},
	computed: {
		videoSrc() {
			return `https://player.vimeo.com/video/${this.videoId}?title=0&byline=0&portrait=0`
		},
	},
	metaInfo: () => ({
		script: [
			{
				type: 'text/javascript',
				src: 'https://player.vimeo.com/api/player.js',
			},
		],
	}),
}
</script>

<style scoped lang="scss">
.video-embed {
	background-color: black;
	border-radius: 5px;
	position: relative;
}
</style>
